export const ConfigIllustration = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="150" height="161" fill="none">
    <path fill="#E2D9FF" d="M75 150c41.421 0 75-33.579 75-75S116.421 0 75 0 0 33.579 0 75s33.579 75 75 75Z" />
    <g filter="url(#a)">
      <path fill="#fff" d="M118 43H32a5 5 0 0 0-5 5v105a5 5 0 0 0 5 5h86a5 5 0 0 0 5-5V48a5 5 0 0 0-5-5Z" />
    </g>
    <path fill="#CAB8FF" d="M65 58H39a3 3 0 1 0 0 6h26a3 3 0 1 0 0-6Z" />
    <path fill="#EEE8FF" d="M83 71H39a3 3 0 1 0 0 6h44a3 3 0 1 0 0-6Z" />
    <path fill="#CAB8FF" d="M65 85H39a3 3 0 1 0 0 6h26a3 3 0 1 0 0-6Z" />
    <path fill="#EEE8FF" d="M83 98H39a3 3 0 1 0 0 6h44a3 3 0 1 0 0-6Z" />
    <path fill="#CAB8FF" d="M65 131H39a3 3 0 1 0 0 6h26a3 3 0 1 0 0-6Z" />
    <path fill="#EEE8FF" d="M83 144H39a3 3 0 1 0 0 6h44a3 3 0 1 0 0-6Z" />
    <g filter="url(#b)">
      <path fill="#8508E8" d="M118 83H32a5 5 0 0 0-5 5v30a5 5 0 0 0 5 5h86a5 5 0 0 0 5-5V88a5 5 0 0 0-5-5Z" />
    </g>
    <path fill="#CAB8FF" d="M65 94H39a3 3 0 1 0 0 6h26a3 3 0 1 0 0-6Z" />
    <path fill="#fff" d="M83 107H39a3 3 0 1 0 0 6h44a3 3 0 1 0 0-6Z" />
    <defs>
      <filter
        id="a"
        width="108"
        height="127"
        x="21"
        y="34"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy="-3" />
        <feGaussianBlur stdDeviation="3" />
        <feColorMatrix values="0 0 0 0 0.788235 0 0 0 0 0.803922 0 0 0 0 0.85098 0 0 0 0.349 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_10_14774" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_10_14774" result="shape" />
      </filter>
      <filter
        id="b"
        width="108"
        height="52"
        x="21"
        y="74"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy="-3" />
        <feGaussianBlur stdDeviation="3" />
        <feColorMatrix values="0 0 0 0 0.788235 0 0 0 0 0.803922 0 0 0 0 0.85098 0 0 0 0.349 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_10_14774" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_10_14774" result="shape" />
      </filter>
    </defs>
  </svg>
)
