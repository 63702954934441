// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kKvO3{height:100%}.FY0Jq{max-width:394px;margin-top:8px;margin-bottom:16px;text-align:center}", "",{"version":3,"sources":["webpack://./src/components/assistant/configuration/notConfiguredMessage/NotConfiguredMessage.module.scss"],"names":[],"mappings":"AAAA,OACE,WAAA,CAGF,OACE,eAAA,CACA,cAAA,CACA,kBAAA,CACA,iBAAA","sourcesContent":[".notConfiguredMsgContainer {\n  height: 100%;\n}\n\n.text {\n  max-width: 394px;\n  margin-top: 8px;\n  margin-bottom: 16px;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notConfiguredMsgContainer": "kKvO3",
	"text": "FY0Jq"
};
export default ___CSS_LOADER_EXPORT___;
