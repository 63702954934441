import { WppActionButton, WppIconSend, WppSkeleton, WppTypography } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import clsx from 'clsx'
import { useState, useMemo, FormEvent, KeyboardEvent, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { MentionsInput, Mention } from 'react-mentions'
import { Updater as ImmerUpdater } from 'use-immer'

import styles from 'components/assistant/chat/Chat.module.scss'
import { ChatBottomText } from 'components/assistant/chat/chatBottomText/ChatBottomText'
import { ChatBubble } from 'components/assistant/chat/chatBubble/ChatBubble'
import { ChatInstructions } from 'components/assistant/chat/chatInstructions/ChatInstructions'
// import { Documents } from 'components/assistant/chat/documents/Documents'
// import { ValuationBrand } from 'components/assistant/chat/valuationBrand/ValuationBrand'
import { Flex } from 'components/common/flex/Flex'
import { useAssistant } from 'hooks/useAssistant'
import { useHasPermissions } from 'hooks/useHasPermissions'
import { ConversationDto, ConversationMessageDto } from 'types/dto/ConversationDto'

const NEW_CONVERSATION_FLAG = 'newConversationFlag'
const QUESTION_MAX_LENGTH = 2000

const mentionOptions = [
  { id: 'appsuggestion', display: 'appsuggestion' },
  { id: 'audience', display: 'audience' }, // TODO handle this mention only on FE
  { id: 'campaign', display: 'campaign' },
  { id: 'brand', display: 'brand' },
  { id: 'data', display: 'data' },
  { id: 'BAV', display: 'BAV' },
  { id: 'inspiration', display: 'inspiration' },
]

interface Props {
  avatarUrl: string | MayBeNull<string>
  conversation: ConversationDto | undefined
  isConversationSaved: boolean
  isConversationFromHistory: boolean
  updateConversation: ImmerUpdater<ConversationDto | undefined>
  onClearConversation: ({
    conversationId,
    startNewConversation,
  }: {
    conversationId: string
    startNewConversation?: boolean
  }) => void
  questionInputVisible: boolean
}

export const Chat = ({
  avatarUrl,
  conversation,
  isConversationSaved,
  isConversationFromHistory,
  updateConversation,
  onClearConversation,
  questionInputVisible,
}: Props) => {
  const { t } = useTranslation()
  const { hasAccessToChatAssistantConfig } = useHasPermissions()
  const { startConversation, askQuestion } = useAssistant()

  const [question, setQuestion] = useState('')
  const [questionCharCount, setQuestionCharCount] = useState(0)
  const [firstQuestion, setFirstQuestion] = useState('')
  const [conversationIdOfLoadingAnswer, setConversationIdOfLoadingAnswer] = useState<string | null>(null)
  const [showErrorChatBubble, setShowErrorChatBubble] = useState(false)

  const answerIsLoading = useMemo(
    () => conversationIdOfLoadingAnswer === conversation?.id || conversationIdOfLoadingAnswer === NEW_CONVERSATION_FLAG,
    [conversationIdOfLoadingAnswer, conversation],
  )

  useEffect(() => {
    setShowErrorChatBubble(false)
  }, [conversation?.id])

  const onQuestionKeyDown = (event: KeyboardEvent<HTMLTextAreaElement> | KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      handleSubmitQuestion()
    }
  }

  const onSubmitQuestion = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    event.stopPropagation()
    handleSubmitQuestion()
  }

  const handleSubmitQuestion = async () => {
    if (!question.trim() || question.length > QUESTION_MAX_LENGTH) return

    setConversationIdOfLoadingAnswer(conversation?.id ? conversation.id : NEW_CONVERSATION_FLAG)
    const questionCopy = question
    setQuestion('')
    try {
      if (!conversation) {
        setFirstQuestion(questionCopy)
        const newConversation = await startConversation({ saved: isConversationSaved, question: questionCopy })
        if (newConversation) {
          setFirstQuestion('')
          updateConversation(newConversation)
        } else {
          setShowErrorChatBubble(true)
        }
        return
      }
      const questionMessage = {
        content: questionCopy,
        role: 'user',
        chatId: conversation.id,
      } as ConversationMessageDto
      updateConversation(conversationDraft => {
        if (!conversationDraft) return
        conversationDraft.messages = [...conversation.messages, questionMessage]
      })
      const answerResponse = await askQuestion({ conversationId: conversation.id, question: questionCopy })
      if (answerResponse) {
        updateConversation(conversationDraft => {
          if (!conversationDraft) return
          const answer = Array.isArray(answerResponse) ? answerResponse[1] : answerResponse
          conversationDraft.messages = [...conversation.messages, questionMessage, answer]
        })
      } else {
        setShowErrorChatBubble(true)
      }
    } catch (err) {
      console.error(err)
    } finally {
      setConversationIdOfLoadingAnswer(null)
    }
  }

  // TODO move this to scss file(?)
  let suggestionsStyle = {
    input: {
      height: 136,
      overflow: 'auto',
    },
    highlighter: {
      height: 136,
      padding: '9px 48px 9px 12px',
      lineHeight: '22px',
      overflow: 'auto',
    },
    suggestions: {
      item: {
        minWidth: '180px',
        marginBottom: '4px',
        padding: '5px 8px',
        borderRadius: 'var(--wpp-border-radius-s)',
        '&focused': {
          backgroundColor: 'var(--wpp-grey-color-200)',
        },
      },
    },
  }

  return (
    <div>
      <div className={styles.chatWrapper}>
        <div className={styles.chat}>
          {!firstQuestion && !conversation && <ChatInstructions />}
          {firstQuestion && (
            <>
              <ChatBubble isQuestion avatarUrl={avatarUrl} text={firstQuestion} />
            </>
          )}
          {conversation?.messages.slice(1).map((message, index) => (
            <ChatBubble isQuestion={message.role === 'user'} avatarUrl={avatarUrl} text={message.content} key={index} />
          ))}
          {answerIsLoading && (
            <WppSkeleton variant="rectangle" width="100%" height="140" className={styles.answerSkeletonPlaceholder} />
          )}
          {/* TODO remove this when all genie message types are integrated */}
          {/* {conversation?.messages && <ValuationBrand key="valuationBrand" />}
          {conversation?.messages && (
            <Documents key="documents" keyword={conversation?.messages[conversation?.messages?.length - 2].content} />
          )} */}
          {showErrorChatBubble && <ChatBubble isQuestion={false} text={t('assisstant_errors.no_answer')} isError />}
          <ChatBottomText
            conversation={conversation}
            isConversationSaved={isConversationSaved}
            isConversationFromHistory={isConversationFromHistory}
            onClearConversation={onClearConversation}
          />
        </div>
      </div>

      {questionInputVisible && (
        <form
          className={clsx(
            styles.questionInputWrapper,
            hasAccessToChatAssistantConfig && styles.questionInputWrapperFooterOffset,
          )}
          onSubmit={onSubmitQuestion}
        >
          <MentionsInput
            placeholder={t('question_input.placeholder')}
            value={question}
            onChange={e => {
              setQuestion(e.target.value)
              setQuestionCharCount(e.target.value.length)
            }}
            maxLength={QUESTION_MAX_LENGTH}
            disabled={answerIsLoading || conversation?.tokenLimitReached}
            onKeyDown={onQuestionKeyDown}
            customSuggestionsContainer={children => (
              <div className={styles.mentionSuggestionsContainer}>{children}</div>
            )}
            className={clsx(styles.mentionsInput, question.length > QUESTION_MAX_LENGTH && styles.mentionsInputError)}
            style={suggestionsStyle}
          >
            <Mention
              trigger="@"
              markup="@[__display__](__id__)"
              displayTransform={mention => `@${mention}`}
              data={mentionOptions}
              appendSpaceOnAdd
              className={styles.mention}
              renderSuggestion={suggestion => (
                <div className={styles.mentionSuggestion}>
                  <WppTypography type="s-body">{suggestion.display}</WppTypography>
                </div>
              )}
            />
          </MentionsInput>

          <Flex justify="end" className={styles.questionCharacterLimit}>
            <WppTypography
              type="xs-body"
              className={clsx(
                question.length > QUESTION_MAX_LENGTH
                  ? styles.questionCharacterLimitLabelError
                  : styles.questionCharacterLimitLabel,
              )}
            >
              {t('question_input.characters_label')}:
            </WppTypography>
            <WppTypography
              type="xs-strong"
              className={clsx(question.length > QUESTION_MAX_LENGTH && styles.questionCharacterLimitLabelError)}
            >
              &nbsp;{questionCharCount}/{QUESTION_MAX_LENGTH}
            </WppTypography>
          </Flex>
          <WppActionButton type="submit" className={styles.submitQuestionButton} disabled={answerIsLoading}>
            <WppIconSend size="m" color="var(--wpp-grey-color-600)" />
          </WppActionButton>
        </form>
      )}
    </div>
  )
}
