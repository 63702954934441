import { WppSpinner } from '@platform-ui-kit/components-library-react'
import { useOs } from '@wpp-open/react'
import { useState, useMemo } from 'react'
import { useImmer } from 'use-immer'

import styles from 'components/assistant/assistantPopover/assistantContent/AssistantContent.module.scss'
import { Footer } from 'components/assistant/assistantPopover/assistantContent/footer/Footer'
import { HeaderControls } from 'components/assistant/assistantPopover/assistantContent/headerControls/HeaderControls'
import { Chat } from 'components/assistant/chat/Chat'
import { NotConfiguredMessage } from 'components/assistant/configuration/notConfiguredMessage/NotConfiguredMessage'
import { SavedConversationsList } from 'components/assistant/savedConversationsList/SavedConversationsList'
import { Flex } from 'components/common/flex/Flex'
import { AssistantTabs } from 'constants/ui'
import { useAssistant } from 'hooks/useAssistant'
import { useAssistantConfigurator } from 'hooks/useAssistantConfigurator'
import { useHasPermissions } from 'hooks/useHasPermissions'
import { ConversationDto } from 'types/dto/ConversationDto'

interface Props {
  onOpenAssistantSettings: () => void
}

export const AssistantContent = ({ onOpenAssistantSettings }: Props) => {
  const { osContext } = useOs()
  const { avatarUrl } = osContext.userDetails
  const { deleteConversation, patchConversation } = useAssistant()
  const { hasAccessToChatAssistantConfig } = useHasPermissions()
  const { connectionDetails, isConnectionDetailsLoading } = useAssistantConfigurator()

  const [selectedTab, setSelectedTab] = useState<AssistantTabs>(AssistantTabs.CHAT)

  const [conversation, updateConversation] = useImmer<ConversationDto | undefined>(undefined)
  const [isSaveConversationChecked, setIsSaveConversationChecked] = useState(true)

  const [selectedConversation, updateSelectedConversation] = useImmer<ConversationDto | undefined>(undefined)
  const [isSaveSelectedConversationChecked, setIsSaveSelectedConversationChecked] = useState(true)

  const saveToggleValue = useMemo(() => {
    if (selectedTab === AssistantTabs.CHAT) {
      return isSaveConversationChecked
    }
    return isSaveSelectedConversationChecked
  }, [selectedTab, isSaveConversationChecked, isSaveSelectedConversationChecked])

  const handleSelectConversation = (conversation: ConversationDto | undefined) => {
    updateSelectedConversation(conversation)
    if (conversation) {
      setIsSaveSelectedConversationChecked(conversation.saved)
    }
  }

  const handleGoBackToConversationsHistory = () => {
    updateSelectedConversation(undefined)
  }

  const handleSaveConversationCheckChange = (checked: boolean) => {
    if (conversation) {
      setIsSaveConversationChecked(checked)
      patchConversation({ conversationId: conversation.id, saved: checked })
      return
    }
    if (selectedConversation) {
      setIsSaveSelectedConversationChecked(checked)
      patchConversation({ conversationId: selectedConversation.id, saved: checked })
      return
    }
    setIsSaveConversationChecked(checked)
  }

  const handleClearConversation = ({ conversationId }: { conversationId: string }) => {
    updateConversation(undefined)
    if (!isSaveConversationChecked) deleteConversation({ conversationId })
  }

  const handleClearSelectedConversation = ({
    conversationId,
    startNewConversation,
  }: {
    conversationId: string
    startNewConversation?: boolean
  }) => {
    handleGoBackToConversationsHistory()
    updateSelectedConversation(undefined)
    if (!isSaveSelectedConversationChecked) deleteConversation({ conversationId })

    if (startNewConversation) {
      setSelectedTab(AssistantTabs.CHAT)
    }
  }

  if (isConnectionDetailsLoading) {
    return (
      <div className={styles.spinnerContainer}>
        <Flex justify="center" align="center" className={styles.spinnerContainer}>
          <WppSpinner size="m" />
        </Flex>
      </div>
    )
  } else if (!connectionDetails) {
    return (
      <div className={styles.container}>
        <NotConfiguredMessage onOpenAssistantSettings={onOpenAssistantSettings} />
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <HeaderControls
        selectedTab={selectedTab}
        onTabChange={tab => setSelectedTab(tab)}
        showSaveConversationToggle={selectedTab === AssistantTabs.CHAT || !!selectedConversation}
        isSaveConversationChecked={saveToggleValue}
        onSaveConversationCheckChange={handleSaveConversationCheckChange}
        showBackButton={!!selectedConversation}
        onBackButtonClick={handleGoBackToConversationsHistory}
      />
      <div style={selectedTab === AssistantTabs.CHAT ? { display: 'block' } : { display: 'none' }}>
        <Chat
          avatarUrl={avatarUrl}
          conversation={conversation}
          isConversationSaved={saveToggleValue}
          isConversationFromHistory={false}
          updateConversation={updateConversation}
          onClearConversation={handleClearConversation}
          questionInputVisible={selectedTab === AssistantTabs.CHAT}
        />
      </div>
      <div
        style={
          selectedTab === AssistantTabs.SAVED_CONVERSATIONS && selectedConversation
            ? { display: 'block' }
            : { display: 'none' }
        }
      >
        <Chat
          avatarUrl={avatarUrl}
          conversation={selectedConversation}
          isConversationSaved={saveToggleValue}
          isConversationFromHistory={true}
          updateConversation={updateSelectedConversation}
          onClearConversation={handleClearSelectedConversation}
          questionInputVisible={!!selectedConversation}
        />
      </div>
      {selectedTab === AssistantTabs.SAVED_CONVERSATIONS && !selectedConversation && (
        <SavedConversationsList onSelectConversation={conversation => handleSelectConversation(conversation)} />
      )}
      {hasAccessToChatAssistantConfig && <Footer onOpenAssistantSettings={onOpenAssistantSettings} />}
    </div>
  )
}
