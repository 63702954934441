import { WppTypography, WppButton } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import { ConfigIllustration } from 'components/assistant/configuration/configIllustration/ConfigIllustration'
import styles from 'components/assistant/configuration/notConfiguredMessage/NotConfiguredMessage.module.scss'
import { Flex } from 'components/common/flex/Flex'
import { useHasPermissions } from 'hooks/useHasPermissions'

interface Props {
  onOpenAssistantSettings: () => void
}

export const NotConfiguredMessage = ({ onOpenAssistantSettings }: Props) => {
  const { t } = useTranslation()
  const { hasAccessToChatAssistantConfig } = useHasPermissions()

  const message = hasAccessToChatAssistantConfig
    ? t('admin.assistant_not_configured_message.admin')
    : t('admin.assistant_not_configured_message.non_admin')

  return (
    <Flex className={styles.notConfiguredMsgContainer} direction="column" justify="center" align="center">
      <div>
        <ConfigIllustration />
      </div>
      <WppTypography tag="h2" type="m-strong" className={styles.text}>
        {message}
      </WppTypography>
      {hasAccessToChatAssistantConfig && (
        <WppButton onClick={onOpenAssistantSettings}>{t('admin.configure_assistant')}</WppButton>
      )}
    </Flex>
  )
}
