import { useOs } from '@wpp-open/react'
import { useCallback } from 'react'

import { Permission } from 'constants/permission'
import { hasPermission as checkPermission } from 'utils/permissions'

export const useHasPermissions = () => {
  const { osContext } = useOs()

  const { permissions, tenant } = osContext

  const hasPermission = useCallback(
    (permission?: string) => !permission || checkPermission(permissions, permission, tenant.azMeta.organizationsId),
    [permissions, tenant.azMeta.organizationsId],
  )

  return {
    hasAccessToChatAssistant: hasPermission(Permission.OS_OPENAI_CHAT_APP_ACCESS),
    hasAccessToChatAssistantConfig: hasPermission(Permission.OS_OPENAI_CHAT_APP_MANAGE),
  }
}
