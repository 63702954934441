import { WppAvatar, WppTypography, WppIconBot } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import clsx from 'clsx'

import styles from 'components/assistant/chat/chatBubble/ChatBubble.module.scss'
import { Flex } from 'components/common/flex/Flex'

interface Props {
  isQuestion?: boolean
  avatarUrl?: string | MayBeNull<string>
  text: string
  isError?: boolean
}

export const ChatBubble = ({ isQuestion, avatarUrl, text, isError }: Props) => {
  return (
    <Flex
      className={clsx(styles.chatBubble, isQuestion && styles.chatBubbleQuestion)}
      direction="row"
      wrap="nowrap"
      gap={12}
    >
      {isQuestion ? (
        <WppAvatar size="s" src={avatarUrl ?? ''} className={styles.avatar} />
      ) : (
        <WppIconBot
          height={32}
          width={32}
          color={isError ? 'var(--wpp-warning-color-500)' : 'var(--wpp-dataviz-color-seq-grey-600)'}
        />
      )}
      <WppTypography tag="p" type="s-body" className={clsx(isError && styles.errorText)}>
        {text}
      </WppTypography>
    </Flex>
  )
}
